@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap");
:root {
  --color-primary: #404145;
  --color-status: rgb(98, 98, 98);
  --color-secondary: #0fd3bb;
  --color-lightFalcon: rgba(15, 211, 187, 0.3);
  --color-lightGrey: #dadbdd;
  --color-lightBackground: #fcfcf9;
  --color-green: #00a67e;
  --color-veryLightGrey: #d3d3d3;
  --color-addBox: #e7e5e5;
  --color-mediumGrey: #c2c2c2;
  --color-darkGrey: #757575;
  --color-white: #fff;
  --color-black: #000;
  --color-github: #171515;
  --color-google: #186dee;
  --color-facebook: #3b5998;
  --color-blue: #446ee7;
  --color-red: #ff0000;
  --color-danger: #ff424c;
  --color-bgPending: #fff3ce;
  --color-pending: #dca500;
  --color-bgRejected: #ffe8e8;
  --color-rejected: #ff0000;
  --color-bgCancel: #ebe7ff;
  --color-cancel: #3300ff;
}
.text-xs {
  font-size: 0.6rem;
  font-weight: 400;
}
.text-sm {
  font-size: 0.8rem;
  font-weight: 400;
}
.text-md {
  font-size: 1rem;
  font-weight: 400;
}
.text-lg {
  font-size: 1.2rem;
  font-weight: 400;
}
.text-xl {
  font-size: 1.5rem;
  font-weight: 400;
}
.text-2xl {
  font-size: 2.2rem;
  font-weight: 400;
}
.font-semibold {
  font-weight: 600;
}
.placeholder-grey {
  background-color: var(--color-mediumGrey) !important;
}
.hover-underlined:hover {
  text-decoration: underline !important;
  cursor: pointer;
}
.text-center {
  text-align: center;
}
* {
  font-family: "MS_Medium", "NN_Regular", "sans";
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  text-decoration: none !important;
}

p {
  margin-bottom: 0 !important;
  word-wrap: break-word !important;
}
img {
  max-width: 100%;
  height: auto;
}
.falcon-clr {
  color: rgb(15, 211, 187);
}

.w-600 {
  max-width: 600px;
}
/* body { */
/* min-height: 100vh; */
/* background-color: rgb(238, 245, 247) !important; */
/* } */
body::-webkit-scrollbar {
  display: none;
}
/* body::-webkit-scrollbar {
  width: 5px !important;
}
body::-webkit-scrollbar-track {
  background-color: #dadbdd;
}
body::-webkit-scrollbar-thumb {
  background-color: #0fd3bb;
  opacity: 0.7;
} */

/* Fonts */

@font-face {
  font-family: "MS_Light";
  src: url("./assets/fonts/Montserrat/Montserrat-Light.ttf");
}

@font-face {
  font-family: "MS_Medium";
  src: url("./assets/fonts/Montserrat/Montserrat-Medium.ttf");
}
@font-face {
  font-family: "MS_Regular";
  src: url("./assets/fonts/Montserrat/Montserrat-Regular.ttf");
}
@font-face {
  font-family: "MS_SemiBold";
  src: url("./assets/fonts/Montserrat/Montserrat-SemiBold.ttf");
}
@font-face {
  font-family: "MS_Bold";
  src: url("./assets/fonts/Montserrat/Montserrat-Bold.ttf");
}
@font-face {
  font-family: "MS_ExtraBold";
  src: url("./assets/fonts/Montserrat/Montserrat-ExtraBold.ttf");
}
@font-face {
  font-family: "MS_Black";
  src: url("./assets/fonts/Montserrat/Montserrat-Black.ttf");
}

@font-face {
  font-family: "NN_Light";
  src: url("./assets/fonts/Nunito/Nunito-Light.ttf");
}
@font-face {
  font-family: "NN_Regular";
  src: url("./assets/fonts/Nunito/Nunito-Regular.ttf");
}
@font-face {
  font-family: "NN_SemiBold";
  src: url("./assets/fonts/Nunito/Nunito-SemiBold.ttf");
}
@font-face {
  font-family: "NN_Bold";
  src: url("./assets/fonts/Nunito/Nunito-Bold.ttf");
}

.swiper-slider-overrides {
  --swiper-navigation-size: 28px;
}

@media (max-width: 768px) {
  body::-webkit-scrollbar {
    display: block;
    width: 10px;
  }

  body::-webkit-scrollbar-track {
    background-color: var(--color-lightGrey);
  }

  body::-webkit-scrollbar-thumb {
    background-color: var(--color-secondary);
    opacity: 0.7;
  }
}
